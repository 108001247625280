import React from 'react'

type IIconProps = {
  color?: string
}

const ProfileIcon: React.FC<React.SVGProps<SVGSVGElement> & IIconProps> = ({
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 20" {...rest}>
      <g fillRule="evenodd">
        <path
          d="M8 0c3.867477 0 7 3.13373682 7 7 0 2.3614514-1.1686664 4.4494045-2.9518 5.7168751C14.3735026 13.8602493 16 16.4072388 16 19.0012162c0 .5518076-.4457738 1.0000006-1 1.0000006-.5542082 0-1-.448193-1-1.0000006 0-2.302411-1.8433662-4.5469907-4.0481868-4.8698824-.542169-.0783133-1.1686754-.1301206-1.9518132-.1301206-.7831282 0-1.4096346.0518073-1.9397554.1301206C3.855424 14.4542255 2 16.6988052 2 19.0012162c0 .5518076-.4457822 1.0000006-1 1.0000006-.5542166 0-1-.448193-1-1.0000006 0-2.5939774 1.626507-5.1409669 3.9638578-6.2843411C2.168676 11.4494045 1 9.3614514 1 7c0-3.86626318 3.1325326-7 7-7zm.0000018 2.0000012c-2.7590348 0-5 2.23855556-5 5 0 2.7614504 2.2409652 5 5 5 2.7590408 0 5-2.2385496 5-5 0-2.76144444-2.2409592-5-5-5z"
          fill={color}
          fillRule="nonzero"
          stroke="none"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}

export default ProfileIcon
